import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../assets/logo.svg";
import { useLocation } from "react-router-dom";

const SubFooter = () => {
  const location = useLocation();
  const [ifSend, setIfSend] = useState(false);
  useEffect(() => {
    location.pathname.includes("send") ? setIfSend(true) : setIfSend(false);
  }, [location]);
  return (
    <>
      <div className={ifSend ? "subFooter disNone" : "subFooter"}>
        <div className="colImg flex flexRow flexWrap">
          <div className="imgBck"></div>
          <div className="textFooter">
            <h1>
              Subite a tu <br />
              <strong>Peugeot 0km</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container-lg flex flexRow flexWrap space">
          <p>
            <img src={logo} alt="logo" />
            Peugeot Plan de Ahorro -
            <a
              href="https://tengouncero.com.ar/tyc"
              target="_blank"
              style={{ display: "block" }}
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>
          </p>
          <p>
            Mango&amp;Cía
            <a
              href="https://mangoycia.com/?utm_source=partners&amp;utm_medium=peugeotgiama&amp;utm_campaign=partners"
              target="_blank"
            >
              Agencia de Performance
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SubFooter;
