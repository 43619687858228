import React from "react";
import "./index.css";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header flex flexCenter flexCol">
      <div className="logo container flex flexCenter flex">
        <span>Giama</span>
        <Link to={"/"}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
